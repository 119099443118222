export const Types = {
  SET_USER_DATA: 'USER/SET_USER_DATA',
  LOGOUT: 'USER/LOGOUT',
};

interface UserDucks {
  type: string,
  token: string | null,
  user: object,
  isLogged: boolean,
  loading: boolean,
  updateLoading: boolean,
  updateError: boolean | null,
  userData: any,
  error: string | null,
}

const initialState: UserDucks = {
  type: '',
  token: sessionStorage.getItem('token'),
  user: {},
  isLogged: sessionStorage.getItem('isLogged') === 'true',
  loading: false,
  updateLoading: false,
  updateError: null,
  userData: null,
  error: null,
};

export const setUserData = ({ userData, token }: Partial<UserDucks>) => ({
  type: Types.SET_USER_DATA,
  userData,
  token,
});

export const logout = (): Partial<UserDucks> => {
  sessionStorage.clear();
  return {
    type: Types.LOGOUT,
  }
};

const UserReducer = (state = initialState, action: UserDucks): UserDucks => {
  switch (action.type) {
    case Types.SET_USER_DATA:
      return {
        ...state,
        loading: false,
        error: null,
        user: { ...state.user, ...action.userData },
        token: action.token,
        isLogged: true,
      };
    case Types.LOGOUT:
      return { ...initialState, isLogged: false, token: null };
    default:
      return state;
  }
};

export default UserReducer;
