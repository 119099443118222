import React from 'react';
import { useSelector } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';
import {
  CToaster,
  CToast,
  CToastHeader,
  CToastBody,
} from '@coreui/react'
import './scss/style.scss';
import 'bootstrap-icons/font/bootstrap-icons.scss';
import 'bootstrap/scss/bootstrap.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/login/Login'));

const App = () => {
  const { toasts = [] } = useSelector<any, any>(state => state.AppReducer);
  const isLogged = useSelector<any, any>(state => state.UserReducer.isLogged);

  const toasters: any = (() => {
    return toasts.reduce((toasters: any, toast: any) => {
      const position: NonNullable<CToaster["position"]> = toast.position || 'bottom-right';
      toasters[position] = toasters[position] || []
      toasters[position].push(toast)
      return toasters
    }, {})
  })()

  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        {(Object.keys(toasters) as NonNullable<CToaster['position']>[]).map((toasterKey) => (
          <CToaster
            position={toasterKey}
            key={'toaster' + toasterKey}
          >
            {toasters[toasterKey as any].map((toast: any, key: any) => (
              <CToast
                key={'toast' + key}
                show={true}
                autohide={toast.autohide || 1500}
                fade={toast.fade}
                color={toast.color || ''}
              >
                <CToastHeader closeButton={toast.closeButton}>
                  {toast.title}
                </CToastHeader>
                <CToastBody>
                  {toast.text}
                </CToastBody>
              </CToast>
            ))}
          </CToaster>
        ))}
        <Switch>
          {/* @ts-ignore */}
          {!isLogged && <Route path="/" name="LogIn" render={props => <Login {...props} />} />}
          {/* @ts-ignore */}
          {isLogged && <Route path="/" name="Home" render={props => <TheLayout {...props} />} />}
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
}

export default App;
