export const Types = {
  SET_ONBOARDING: 'APP/SET_ONBOARDING',
  SET_LOADING: 'APP/SET_LOADING',
  SET_VIDEO_RUNNING: 'APP/SET_VIDEO_RUNNING',
  TOGGLE_SIDEBAR: 'APP/TOGGLE_SIDEBAR',
  SET_TOAST: 'APP/SET_TOAST',
};

interface AppDucks {
  type: string,
  doneOnboarding: boolean,
  token: string,
  loadingPopUp: boolean,
  videoRunningId: string | null,
  sidebarShow: string,
  toasts: any[],
  toast: any,
}

const initialState: AppDucks = {
  type: '',
  doneOnboarding: false,
  token: '123',
  loadingPopUp: false,
  videoRunningId: null,
  sidebarShow: 'responsive',
  toasts: [],
  toast: null,
};

export const setOnboarding = (doneOnboarding: boolean) => ({
  type: Types.SET_ONBOARDING,
  doneOnboarding,
});

export const setLoading = (loadingPopUp: boolean) => ({
  type: Types.SET_LOADING,
  loadingPopUp,
});

export const setVideoRunning = (videoId : string | null) => ({
  type: Types.SET_VIDEO_RUNNING,
  videoId,
});

export const toggleSidebar = (sidebarShow : string | boolean) => ({
  type: Types.TOGGLE_SIDEBAR,
  sidebarShow,
});

export const setToast = (toast : any) => ({
  type: Types.SET_TOAST,
  toast,
});


const OnboardingReducer = (state = initialState, action: AppDucks): AppDucks => {
  switch (action.type) {
    case Types.SET_ONBOARDING:
      return { ...state, doneOnboarding: action.doneOnboarding };
    case Types.SET_LOADING:
      return { ...state, loadingPopUp: action.loadingPopUp };
    case Types.SET_VIDEO_RUNNING:
      return { ...state, videoRunningId: action.videoRunningId };
    case Types.TOGGLE_SIDEBAR:
      return { ...state, sidebarShow: action.sidebarShow };
    case Types.SET_TOAST:
      return { ...state, toasts: [...state.toasts, action.toast] };
    default:
      return state;
  }
};

export default OnboardingReducer;
